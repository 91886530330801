<template>
  <article
    class="bg-slate-800 p-3 leading-none rounded flex flex-col justify-between space-y-3"
  >
    <div class="flex flex-col lg:flex-row lg:gap-2 lg:items-center">
      <h3 class="text-xl font-semibold">
        {{ name }}
      </h3>
      <span v-if="code" class="text-sm text-slate-600 font-semibold">
        #{{ code }}
      </span>
    </div>

    <ul class="flex flex-col mb-3 text-sm">
      <li v-if="date" class="flex items-center gap-1">
        <CalendarIcon class="w-4 h-4" />Inizio: {{ dateTime(date) }}
      </li>
      <li v-if="dateEnd" class="flex items-center gap-1">
        <CalendarIcon class="w-4 h-4" />Fine: {{ dateTime(dateEnd) }}
      </li>
    </ul>

    <div class="flex items-center justify-end gap-4">
      <router-link
        :to="{
          name: 'Session',
          params: {
            id: id,
            name: name,
            date: date,
            dateEnd: dateEnd,
            code: code,
          },
        }"
        class="text-slate-500 hover:text-sky-500 transition"
      >
        <EyeIcon class="w-5 h-5" />
      </router-link>

      <router-link
        v-if="parent === 'Sessions'"
        :to="{
          name: 'SessionEdit',
          params: { id, name },
        }"
        class="text-slate-500 hover:text-sky-500 transition"
      >
        <PencilIcon class="w-5 h-5" />
      </router-link>

      <TrashIcon
        @click="deleteSession"
        class="delete-session w-5 h-5 text-slate-500 hover:text-sky-500 transition"
      ></TrashIcon>
    </div>
  </article>
</template>

<script>
import moment from "moment";
moment.locale("it");

import {
  CalendarIcon,
  EyeIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/vue/solid";

export default {
  components: { CalendarIcon, EyeIcon, PencilIcon, TrashIcon },
  props: ["id", "name", "date", "dateEnd", "code", "parent"],
  emits: ['delete'],
  methods: {
    dateTime(value) {
      return moment(value).format("DD MMMM YYYY HH:mm");
    },
    deleteSession() {
      this.$emit('delete', this.id);
    },
  },
};
</script>

<style>
.delete-session {
  cursor: pointer;
}
</style>
