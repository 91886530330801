<template>
  <div>
    <Navbar />

    <div class="px-4 lg:px-2 py-8 2xl:py-10 md:px-12 flex-1">
      <div class="flex items-center justify-between mb-4">
        <h1 class="text-2xl 2xl:text-4xl font-semibold">Serate</h1>

        <div class="flex items-center gap-5">
          <router-link :to="{ name: 'SessionCreate' }" class="btn btn--red"
            >Crea serata</router-link
          >

          <form class="flex flex-col items-start">
            <div class="flex gap-2 items-center">
              <input
                v-model="code"
                type="search"
                name="code"
                id="code"
                autocomplete="given-name"
                class="min-width-10 border-none rounded shadow-sm focus:ring-red-500 focus:ring-2 text-gray-900"
                placeholder="Codice serata..."
              />
            </div>
          </form>
        </div>
      </div>
      <div
        class="grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 2xl:gap-6"
        v-if="code"
      >
        <div v-for="session in filteredSessions" :key="session.id">
          <transition name="fade" mode="out-in">
            <CardSession
              :id="session.id"
              :name="session.name"
              :date="session.date"
              :status="session.status"
              :code="session.code"
              :parent="$route.name"
            />
          </transition>
        </div>
      </div>

      <div
        class="grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 2xl:gap-6"
        v-else
      >
        <div v-for="session in sessions" :key="session.id">
          <CardSession
            :id="session.id"
            :name="session.name"
            :date="session.date"
            :status="session.status"
            :code="session.code"
            :parent="$route.name"
          />
        </div>
      </div>

      <div v-if="code && !filteredSessions.length">
        Non ci sono serate con questo codice
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { getClient } from "@/includes/api";

import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import CardSession from "@/components/CardSession.vue";
import IconSearch from "@/components/icons/IconSearch.vue";

export default {
  components: {
    Footer,
    Navbar,
    CardSession,
    IconSearch,
  },
  props: ["id", "name"],
  data() {
    return {
      code: null,
      sessions: [],
      error: null,
      results: [],
    };
  },
  async mounted() {
    try {
      let id = this.$props.id;
      let apiClient = getClient();
      let sessions = await apiClient.getAllSessions();
      if (Array.isArray(sessions)) {
        this.sessions = sessions;
      }
    } catch (err) {
      console.error(err);
    }
  },
  methods: {
    async findByCode() {
      try {
        let code = this.code;
        console.log(code);
        let apiClient = getClient();
        console.log("Getting sessions...");
        let session = await apiClient.SessionFindByCode(code);
        if (session !== null) {
          this.results.push(session);
          this.error = null;
        }
      } catch (err) {
        console.error(err);
        this.error = "Nessuna serata presente con il codice che hai inserito";
      }
    },
  },
  computed: {
    filteredSessions() {
      return this.sessions.filter((session) => session.code === this.code);
    },
  },
};
</script>
